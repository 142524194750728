export default [
  {
    path: '/',
    name: 'quote-price',
    component: () =>
      import('@/views/pages/inside/Dashboard/QuotePrice/QuotePrice.vue'),
    meta: {
      pageTitle: 'Quote Price',
      breadcrumb: [
        {
          text: 'Quote Price',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/dashboard",
  //   name: "dashboard",
  //   component: () => import("@/views/pages/inside/Dashboard/Dashboard.vue"),
  //   meta: {
  //     pageTitle: "Dashboard",
  //     breadcrumb: [
  //       {
  //         text: "Dashboard",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/dashboard/quote-price',
    name: 'quote-price',
    component: () =>
      import('@/views/pages/inside/Dashboard/QuotePrice/QuotePrice.vue'),
    meta: {
      pageTitle: 'Quote Price',
      breadcrumb: [
        {
          text: 'Quote Price',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/order-analytic',
    name: 'order-analytic',
    component: () =>
      import('@/views/pages/inside/Dashboard/OrderAnalytic/OrderAnalytic.vue'),
    meta: {
      pageTitle: 'Order Analytic',
      breadcrumb: [
        {
          text: 'Order Analytic',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/order-log',
    name: 'order-log',
    component: () => import('@/views/pages/inside/Dashboard/OrderLog.vue'),
    meta: {
      pageTitle: 'Order Log',
      breadcrumb: [
        {
          text: 'Order Log',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-task',
    name: 'my-task',
    component: () => import('@/views/pages/inside/MyTask/MyTask.vue'),
    meta: {
      pageTitle: 'My Task',
      breadcrumb: [
        {
          text: 'My Task',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Outlet-order',
    name: 'Outlet-order',
    component: () =>
      import('@/views/pages/inside/OrderForms/Outlet/OutletOrder.vue'),
    meta: {
      pageTitle: 'Outlet Order',
      breadcrumb: [
        {
          text: 'Outlet Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Outlet-order/finalize',
    name: 'Outlet-finalize',
    component: () =>
      import('@/views/pages/inside/OrderForms/Outlet/OutletOrderFinalize.vue'),
    meta: {
      pageTitle: 'Outlet Order',
      breadcrumb: [
        {
          text: 'Outlet Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Outlet-order/deliverycart',
    name: 'Outlet-deliverycart',
    component: () =>
      import('@/views/pages/inside/OrderForms/Outlet/OutletCart.vue'),
    meta: {
      pageTitle: 'Outlet Order',
      breadcrumb: [
        {
          text: 'Outlet Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Outlet-order/paymentmethod',
    name: 'Outlet-paymentmethod',
    component: () =>
      import('@/views/pages/inside/OrderForms/Outlet/OutletPayment.vue'),
    meta: {
      pageTitle: 'Outlet Order',
      breadcrumb: [
        {
          text: 'Outlet Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Outlet-order/ordersummary',
    name: 'Outlet-ordersummary',
    component: () =>
      import('@/views/pages/inside/OrderForms/Outlet/OutletSummary.vue'),
    meta: {
      pageTitle: 'Outlet Order',
      breadcrumb: [
        {
          text: 'Outlet Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-delivery/Outlet',
    name: 'incoming-order-Outlet',
    component: () => import('@/views/pages/inside/OrderTables/Order.vue'),
    meta: {
      pageTitle: 'Incoming Order',
      breadcrumb: [
        {
          text: 'Incoming Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-delivery/singapore',
    name: 'incoming-order-singapore',
    component: () => import('@/views/pages/inside/OrderTables/Order.vue'),
    meta: {
      pageTitle: 'Incoming Order',
      breadcrumb: [
        {
          text: 'Incoming Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-delivery/agrofun',
    name: 'incoming-order-agrofun',
    component: () => import('@/views/pages/inside/OrderTables/Order.vue'),
    meta: {
      pageTitle: 'Incoming Order',
      breadcrumb: [
        {
          text: 'Incoming Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-delivery/agrobazaar',
    name: 'incoming-order-agrobazaar',
    component: () => import('@/views/pages/inside/OrderTables/Order.vue'),
    meta: {
      pageTitle: 'Incoming Order',
      breadcrumb: [
        {
          text: 'Incoming Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/order-summary/:id',
    name: 'order-summary',
    component: () => import('@/views/pages/inside/OrderSummary.vue'),
    meta: {
      pageTitle: 'Order Summary',
      breadcrumb: [
        {
          text: 'Order Summary',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-order/Outlet',
    name: 'outgoing-order-Outlet',
    component: () => import('@/views/pages/inside/OrderTables/Order.vue'),
    meta: {
      pageTitle: 'My Order',
      breadcrumb: [
        {
          text: 'My Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-order/singapore',
    name: 'outgoing-order-singapore',
    component: () => import('@/views/pages/inside/OrderTables/Order.vue'),
    meta: {
      pageTitle: 'My Order',
      breadcrumb: [
        {
          text: 'My Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-order/agrofun',
    name: 'outgoing-order-agrofun',
    component: () => import('@/views/pages/inside/OrderTables/Order.vue'),
    meta: {
      pageTitle: 'My Order',
      breadcrumb: [
        {
          text: 'My Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-order/agrobazaar',
    name: 'outgoing-order-agrobazaar',
    component: () => import('@/views/pages/inside/OrderTables/Order.vue'),
    meta: {
      pageTitle: 'My Order',
      breadcrumb: [
        {
          text: 'My Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/singapore-order',
    name: 'singapore-order',
    component: () =>
      import('@/views/pages/inside/OrderForms/Singapore/SingaporeOrder.vue'),
    meta: {
      pageTitle: 'Singapore Order',
      breadcrumb: [
        {
          text: 'Singapore Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/singapore-order/finalize',
    name: 'singapore-finalize',
    component: () =>
      import(
        '@/views/pages/inside/OrderForms/Singapore/SingaporeOrderFinalize.vue'
      ),
    meta: {
      pageTitle: 'Singapore Order',
      breadcrumb: [
        {
          text: 'Singapore Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/singapore-order/deliverycart',
    name: 'singapore-deliverycart',
    component: () =>
      import('@/views/pages/inside/OrderForms/Singapore/SingaporeCart.vue'),
    meta: {
      pageTitle: 'Singapore Order',
      breadcrumb: [
        {
          text: 'Singapore Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/singapore-order/paymentmethod',
    name: 'singapore-paymentmethod',
    component: () =>
      import('@/views/pages/inside/OrderForms/Singapore/SingaporePayment.vue'),
    meta: {
      pageTitle: 'Singapore Order',
      breadcrumb: [
        {
          text: 'Singapore Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/singapore-order/ordersummary',
    name: 'singapore-ordersummary',
    component: () =>
      import('@/views/pages/inside/OrderForms/Singapore/SingaporeSummary.vue'),
    meta: {
      pageTitle: 'Singapore Order',
      breadcrumb: [
        {
          text: 'Singapore Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dhl-order',
    name: 'dhl-order',
    component: () => import('@/views/pages/inside/OrderTables/Order.vue'),
    meta: {
      pageTitle: 'DHL Order',
      breadcrumb: [
        {
          text: 'DHL Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dhl-order/create',
    name: 'dhl-order-create',
    component: () => import('@/views/pages/inside/OrderForms/DHL/DHLOrder.vue'),
    meta: {
      pageTitle: 'DHL Order',
      breadcrumb: [
        {
          text: 'DHL Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dhl-order/finalize',
    name: 'dhl-order-finalize',
    component: () =>
      import('@/views/pages/inside/OrderForms/DHL/DHLFinalize.vue'),
    meta: {
      pageTitle: 'DHL Order',
      breadcrumb: [
        {
          text: 'DHL Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dhl-order/deliverycart',
    name: 'dhl-order-deliverycart',
    component: () => import('@/views/pages/inside/OrderForms/DHL/DHLCart.vue'),
    meta: {
      pageTitle: 'DHL Order',
      breadcrumb: [
        {
          text: 'DHL Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dhl-order/paymentmethod',
    name: 'dhl-order-paymentmethod',
    component: () =>
      import('@/views/pages/inside/OrderForms/DHL/DHLPayment.vue'),
    meta: {
      pageTitle: 'DHL Order',
      breadcrumb: [
        {
          text: 'DHL Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dhl-order/ordersummary',
    name: 'dhl-order-ordersummary',
    component: () =>
      import('@/views/pages/inside/OrderForms/DHL/DHLSummary.vue'),
    meta: {
      pageTitle: 'DHL Order',
      breadcrumb: [
        {
          text: 'DHL Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/track-and-trace',
    name: 'track-and-trace',
    component: () =>
      import('@/views/pages/inside/TrackAndTrace/TrackMain/TrackMain.vue'),
    meta: {
      pageTitle: 'Track & Trace',
      breadcrumb: [
        {
          text: 'Track & Trace',
          active: true,
        },
      ],
    },
  },
  {
    path: '/track-and-trace/order-tracking/:id',
    name: 'track-order',
    component: () =>
      import('@/views/pages/inside/TrackAndTrace/TrackOrderID.vue'),
    meta: {
      pageTitle: 'Track & Trace',
      breadcrumb: [
        {
          text: 'Track & Trace',
          active: true,
        },
      ],
    },
  },
  {
    path: '/track-and-trace/delivery-tracking/:id',
    name: 'track-delivery',
    component: () =>
      import('@/views/pages/inside/TrackAndTrace/TrackDeliveryID.vue'),
    meta: {
      pageTitle: 'Track & Trace',
      breadcrumb: [
        {
          text: 'Track & Trace',
          active: true,
        },
      ],
    },
  },
  {
    path: '/postal-coverage',
    name: 'postal-coverage',
    component: () => import('@/views/pages/inside/PostalCoverage.vue'),
    meta: {
      pageTitle: 'Postal Coverage',
      breadcrumb: [
        {
          text: 'Postal Coverage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: () => import('@/views/pages/inside/OrderTables/Order.vue'),
    meta: {
      pageTitle: 'Transaction',
      breadcrumb: [
        {
          text: 'Transaction',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/pages/inside/Report.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/phone-book',
    name: 'phone-book',
    component: () => import('@/views/pages/inside/Driver/PhoneBook.vue'),
    meta: {
      pageTitle: 'Phone Book',
      breadcrumb: [
        {
          text: 'Phone Book',
          active: true,
        },
      ],
    },
  },
  {
    path: '/driver-details/:id',
    name: 'driver-details',
    component: () => import('@/views/pages/inside/Driver/DriverDetails.vue'),
    meta: {
      pageTitle: 'Driver Details',
      breadcrumb: [
        {
          text: 'Driver Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/log-points',
    name: 'log-points',
    component: () => import('@/views/pages/inside/LogPoints/LogPoints.vue'),
    meta: {
      pageTitle: 'Log Points',
      breadcrumb: [
        {
          text: 'Log Points',
          active: true,
        },
      ],
    },
  },
  {
    path: '/log-points-success',
    name: 'log-point-success',
    component: () =>
      import('@/views/pages/inside/LogPoints/LogPointSummary.vue'),
    meta: {
      pageTitle: 'Log Points',
      breadcrumb: [
        {
          text: 'Log Points',
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/inside/MyProfile.vue'),
    meta: {
      pageTitle: 'My Profile',
      breadcrumb: [
        {
          text: 'My Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bulk-order',
    name: 'bulk-order',
    component: () =>
      import('@/views/pages/inside/OrderForms/Bulk/BulkOrder.vue'),
    meta: {
      pageTitle: 'Bulk Order',
      breadcrumb: [
        {
          text: 'Bulk Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bulk-order-cart',
    name: 'bulk-order-cart',
    component: () =>
      import('@/views/pages/inside/OrderForms/Bulk/BulkOrderCart.vue'),
    meta: {
      pageTitle: 'Bulk Order',
      breadcrumb: [
        {
          text: 'Bulk Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bulk-order-paymentmethod',
    name: 'bulk-order-paymentmethod',
    component: () =>
      import('@/views/pages/inside/OrderForms/Bulk/BulkOrderPayment.vue'),
    meta: {
      pageTitle: 'Bulk Order',
      breadcrumb: [
        {
          text: 'Bulk Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bulk-order-success',
    name: 'bulk-order-success',
    component: () =>
      import('@/views/pages/inside/OrderForms/Bulk/BulkOrderSummary.vue'),
    meta: {
      pageTitle: 'Bulk Order',
      breadcrumb: [
        {
          text: 'Bulk Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/route-planning/create-route',
    name: 'create-route',
    component: () =>
      import('@/views/pages/inside/RoutePlanning/CreateRoute.vue'),
    meta: {
      pageTitle: 'Create Route',
      breadcrumb: [
        {
          text: 'Route Planning',
          active: false,
        },
        {
          text: 'Create Route',
          active: true,
        },
      ],
    },
  },
  {
    path: '/route-planning/route-tracker',
    name: 'route-tracker',
    component: () =>
      import('@/views/pages/inside/RoutePlanning/RouteTracker.vue'),
    meta: {
      pageTitle: 'Route List',
      breadcrumb: [
        {
          text: 'Route Planning',
          active: false,
        },
        {
          text: 'Route List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/order-list/:id',
    name: 'order-list',
    component: () => import('@/views/pages/inside/RoutePlanning/OrderList.vue'),
    meta: {
      pageTitle: 'Route Planning > Route List > Order List',
      breadcrumb: [
        {
          text: 'Order List',
          active: false,
        },
      ],
    },
  },
  {
    path: '/distributor/incoming-orders',
    name: 'distributor-incoming-orders',
    component: () =>
      import('@/views/pages/inside/Distributor/IncomingOrders.vue'),
    meta: {
      pageTitle: 'Incoming Orders',
      breadcrumb: [
        {
          text: 'Distributor',
          active: false,
        },
        {
          text: 'Incoming Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/b2bpro/b2b-order',
    name: 'b2bpro-order',
    component: () => import('@/views/pages/inside/B2BPro/B2BOrder.vue'),
    meta: {
      pageTitle: 'B2B Order',
      breadcrumb: [
        {
          text: 'B2B Order',
          active: false,
        },
      ],
    },
  },
  {
    path: '/b2bpro/incoming-orders',
    name: 'b2bpro-incoming-orders',
    component: () =>
      import('@/views/pages/inside/B2BPro/B2BIncomingOrdersPage.vue'),
    meta: {
      pageTitle: 'Incoming Orders',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Incoming Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/b2bpro/accepted-orders',
    name: 'b2bpro-accepted-orders',
    component: () =>
      import('@/views/pages/inside/B2BPro/B2BAcceptedOrdersPage.vue'),
    meta: {
      pageTitle: 'Accepted Orders',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Accepted Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/map_routing',
    name: 'map_routing',
    component: () => import('@/views/pages/inside/Map/Map.vue'),
    meta: {
      pageTitle: 'Create Route',
      breadcrumb: [
        {
          text: 'Create Route',
          active: true,
        },
      ],
    },
  },
  {
    path: '/optimize-route',
    name: 'optimize-route',
    component: () => import('@/views/pages/inside/Map/OptimizeRoute.vue'),
    meta: {
      pageTitle: 'Optimize Route',
      breadcrumb: [
        {
          text: 'Optimize Route',
          active: true,
        },
      ],
    },
  },
];
