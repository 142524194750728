import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Utility from './common/Utilities';

import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueSweetalert2);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

// Plugins
const plugin = {
  install() {
    Vue.helpers = Utility;
    Vue.prototype.$helpers = Utility;
    Vue.prototype.$s3URL = process.env.VUE_APP_API_SPACES_URL;
    Vue.prototype.$baseURL = process.env.VUE_APP_API_INTERNAL_URL;
  },
};

Vue.use(plugin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
