import Vue from 'vue';
import axios from 'axios';

const axiosAuth = axios.create({
  baseURL: `${process.env.VUE_APP_API_AUTH_URL}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const axiosInternal = axios.create({
  baseURL: `${process.env.VUE_APP_API_INTERNAL_URL}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

const axiosProcessing = axios.create({
  baseURL: `${process.env.VUE_APP_API_PROCESSING_URL}/v1`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

axiosInternal.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('userData');
      localStorage.removeItem('accessToken');
      window.location.reload();
    }
    return error.response;
  }
);

Vue.prototype.$http = axiosInternal;
Vue.prototype.$httpAuth = axiosAuth;
Vue.prototype.$httpProcessing = axiosProcessing;

export default axiosAuth;
