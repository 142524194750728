export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/set-password',
    name: '/set-password',
    component: () => import('@/views/pages/auth/SetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/drag-and-drop',
    name: 'drag and drop',
    component: () => import('@/views/pages/auth/DragAndDrop.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/auth-forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/set-password/:token',
    name: 'set-password',
    component: () => import('@/views/pages/auth/SetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/miscellaneous/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
    resource: 'Auth',
  },
];
